<template>
  <app-dropdown-menu
    class="flex items-center gap-2"
    :items="[{ label: 'Accueil', to: '/' }, ...items]"
  >
    <template #default="{ open }">
      <brand-navigation-logo />

      <app-icon
        class="transition"
        :class="{ 'rotate-180': open }"
        icon="ph:caret-down"
      />
    </template>

    <template v-if="!isAuthenticated" #append>
      <app-button
        block
        class="hidden lg:block"
        color="dark"
        data-cy-main-header-signin
        rel="nofollow"
        size="lg"
        :to="{
          name: 'auth-login',
          hash: '#signin',
          query: {
            redirect: $route.fullPath,
          },
        }"
        variant="tonal"
      >
        {{ $t("authentication.signin_navigation_label") }}
      </app-button>
    </template>
  </app-dropdown-menu>
</template>

<script lang="ts" setup>
const { items } = useMainHeaderNavigation();

const { isAuthenticated } = useCurrentUser();
</script>
